import './Main.css';
import React, { useState, useEffect, useRef } from 'react';
import LogoNorwayDigital from '../assets/images/LogoNorwayDigital.png';
import { FaBars, FaDollarSign, FaUserTie, FaUserAlt, FaBuilding, FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Navbar({isMenuActive, setIsMenuActive, menuActive, setMenuActive, menuResponsivo, setMenuResponsivo, handleMenu, handleResponsiveMenu}){

    return(
             <><div className="__home__divisaoCabecalho">
            <nav className="__home__navegacao">
                <Link to="/"><img className="__home__logoNorway" src={LogoNorwayDigital} alt=""></img></Link>
                <ul className={!menuResponsivo ? "__home__itens" : "__home__itens menu_open"}>
                    <div>
                        <li className="__home__item"><a className="__home__link" onClick={() => handleMenu("menuBanco")}>BANCO DIGITAL<FaAngleDown className='__home__iconeNavegacao' /></a></li>
                        {isMenuActive && menuActive === "menuBanco" && (
                            <ul className="__home__dropMenu">
                                <div className="__home__divisaoMenu">
                                    <div className="__home__divisaoIconeMenu">
                                        <FaDollarSign className='__home__IconeMenu' />
                                        {/* <i className="fa-solid fa-dollar-sign __home__IconeMenu"></i> */}
                                    </div>
                                    <span className="__home__tituloMenu">CONTA DIGITAL</span>
                                </div>
                                <div className="__home__linhaMenu"></div>
                                <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">DEPÓSITOS VIA BOLETOS, TED, PIX</a>
                                </li>
                                <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">TRANSFERÊNCIAS POR TED E PIX</a>
                                </li>
                                <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">TRANSFERÊNCIA ENTRE CONTAS DO NORWAY
                                    DIGITAL</a></li>
                                <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">PAGAMENTO DE CONTAS, CONCESSIONÁRIAS
                                    E BOLETOS</a></li>
                                <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">CARTÃO DE CRÉDITO</a></li>
                            </ul>
                        )}
                    </div>
                    <div>
                        <li className="__home__item"><a className="__home__link" href="#" onClick={() => handleMenu("produtos")}>PRODUTOS
                            {/* <i className="fa-solid fa-angle-down __home__iconeNavegacao"></i> */}
                            <FaAngleDown className='__home__iconeNavegacao' />
                        </a></li>
                        {isMenuActive && menuActive === "produtos" && (
                            <ul className="__home__dropMenu drop_duplo">
                                <div>
                                    <div className="__home__divisaoMenu">
                                        <div className="__home__divisaoIconeMenu">
                                            <FaUserTie className='__home__IconeMenu' />
                                            {/* <i className="fa-solid fa-user-tie __home__IconeMenu"></i> */}
                                        </div>
                                        <span className="__home__tituloMenu">PESSOA JURÍDICA</span>
                                    </div>
                                    <div className="__home__linhaMenu"></div>
                                    <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">PLATAFORMA WHITE LABEL</a></li>
                                    <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">GANHOS SOBRE EMPRÉSTIMOS E
                                        FINANCIAMNETOS</a></li>
                                    <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">GANHOS COM CONTAS NOMINAIS E
                                        DEMAIS PRODUTOS</a></li>
                                    <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">INVESTIMENTOS ESTRUTURADOS</a>

                                    </li>
                                    <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">CARTÃO DE CRÉDITO</a>

                                    </li>
                                </div>
                                <div>
                                    <div className="__home__divisaoMenu">
                                        <div className="__home__divisaoIconeMenu">
                                            <FaUserAlt className='__home__IconeMenu' />
                                            {/* <i className="fa-solid fa-user __home__IconeMenu"></i> */}
                                        </div>
                                        <span className="__home__tituloMenu">PESSOA FÍSICA</span>
                                    </div>
                                    <div className="__home__linhaMenu"></div>
                                    <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">RENDA MENSAL DE FIDELIZAÇÃO </a>
                                    </li>
                                    <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">RENDA DE INDICAÇÃO DE PRODUTOS E
                                        SERVIÇOS</a></li>
                                    <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">GANHOS COM CONTAS NOMINAIS E
                                        DEMAIS PRODUTOS</a></li>
                                    <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">INVESTIMENTOS ESTRUTURADOS</a>
                                    </li>
                                </div>
                            </ul>
                        )}
                    </div>
                    <div>
                        <li className="__home__item"><a className="__home__link" href="#" onClick={() => handleMenu("investimento")}>INVESTIMENTOS<FaAngleDown className='__home__iconeNavegacao' /></a></li>
                        {isMenuActive && menuActive === "investimento" && (
                            <ul className="__home__dropMenu">
                                <div className="__home__divisaoMenu">
                                    <div className="__home__divisaoIconeMenu">
                                        <FaBuilding className='__home__IconeMenu' />
                                        {/* <i className="fa-solid fa-building __home__IconeMenu"></i> */}
                                    </div>
                                    <span className="__home__tituloMenu">PESSOA FÍSICA E JURÍDICA</span>
                                </div>
                                <div className="__home__linhaMenu"></div>
                                <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">POUPNORWAY - RENDIMENTO MAIOR QUE A
                                    POUPANÇA</a></li>
                                <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">INVESTNORWAY - FUNDOS DE
                                    INVESTIMENTOS</a></li>
                                <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">RENDA FIXA</a></li>
                                <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">FUNDOS IMOBILIÁRIOS</a></li>
                                <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">AÇÕES</a></li>
                                <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">INVESTIMENTOS EM OPERAÇÕES
                                    ESTRUTURADAS</a></li>
                            </ul>
                        )}

                    </div>
                    <div>
                        <li className="__home__item"><a className="__home__link" href="#" onClick={() => handleMenu("projetos")}>PROJETOS<FaAngleDown className='__home__iconeNavegacao' /></a></li>
                        {isMenuActive && menuActive === "projetos" && (
                            <ul className="__home__dropMenu">
                                <div className="__home__divisaoMenu">
                                    <div className="__home__divisaoIconeMenu">
                                        <FaBuilding className='__home__IconeMenu' />
                                        {/* <i className="fa-solid fa-building __home__IconeMenu"></i> */}
                                    </div>
                                    <span className="__home__tituloMenu">INSTITUIÇÕES, ONGS, ETC</span>
                                </div>
                                <div className="__home__linhaMenu"></div>
                                <li className="__home__itemDrop"><a className="__home__linkDrop" href="#">RENDA - INSTITUIÇÕES DO TERCEIRO
                                    SETOR</a></li>
                            </ul>
                        )}
                    </div>
                    <li className="__home__item"><Link className="__home__link" to="/ajuda">AJUDA</Link></li>
                </ul>
            </nav>
            <div className="__home__divisaoBtnMenu">
                <a href="https://app.norwaydigital.com.br/auth/signin">
                    <button className="__home__btn __home__btnAbrir __home__btnAcessarBanco">ACESSAR CONTA</button>
                </a>
                <button className="__home__btn __home__btnAbrir __home__btnAcessarBanco" onClick={() => handleMenu("tipoPessoa")}>ABRIR NOVA CONTA
                {isMenuActive && menuActive === "tipoPessoa" && (
                    <div class="__home__divisaoTipoPessoa">
                        <a href="https://app.norwaydigital.com.br/auth/signup/personal" class="__home__tipoPessoa">PESSOA FÍSICA</a>
                        <a href="https://app.norwaydigital.com.br/auth/legalPerson" class="__home__tipoPessoa">PESSOA JURÍDICA</a>
                    </div>
                )}
                </button>
                    
                   
            </div>
        </div><FaBars className='__home__menuLateral' onClick={handleResponsiveMenu} /></>
    )
}
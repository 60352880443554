import './Main.css';
import React, { useState } from 'react';
import { FaBars, FaDollarSign, FaUserTie, FaUserAlt, FaBuilding, FaAngleDown } from "react-icons/fa";
import { Link } from "react-router-dom";
import LogoSection from '../assets/images/ImagemSectionPrincipal.png'

// Components
import Navbar from './Navbar';

export default function Main() {

  const [isMenuActive, setIsMenuActive] = useState("") 
  const [menuActive, setMenuActive] = useState("")
  const [menuResponsivo, setMenuResponsivo] = useState(false)
  const [value, setValue] = useState("menuBanco")

  function handleMenu(value){
    console.log(value)
    
    if(menuActive === value){
      setIsMenuActive(false)
      setMenuActive("")
    }
    else{
      setIsMenuActive(true)
      setMenuActive(value)
    }
  }

  function handleResponsiveMenu(){
    if(!menuResponsivo)
      setMenuResponsivo(true)
    else 
      setMenuResponsivo(false)
  }

  function handleActive(){
    setIsMenuActive(false)
    setMenuActive("")
  }

  return (
    <div>
      <div className="animacao_fundo"></div>
      <div className="__home__paginaInicial">
        <header className="__home__cabecalho">
          <Navbar 
          handleMenu={handleMenu} 
          handleResponsiveMenu={handleResponsiveMenu} 
          isMenuActive={isMenuActive}
          setIsMenuActive={setIsMenuActive}
          menuActive={menuActive}
          setMenuActive={setMenuActive}
          menuResponsivo={menuResponsivo}
          setMenuResponsivo={setMenuResponsivo}
          />
          {/* <i className="fa-solid fa-bars "></i> */}
        </header>
        <div className='__home__inicialNorway'>
          <section className='__home_section_principal'>
            <img className='__home_section_principal_imagem' src={LogoSection}></img>
            <h1 className='__home_section_principal_titulo'>Ajudar</h1>
            <p className='__home_section_principal_subtitulo'>para crescer</p>
            <button className='__home_section_principal_botao'>PRODUTOS</button>
          </section>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { BrowserRouter, Redirect, Route, Routes, Navigate } from "react-router-dom";

// Services
import history from "../services/history";

// Pages 
import Main from "../pages/Main";
import Ajuda from "../pages/Ajuda";
import App from "../App";

export default function Rota() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="ajuda" element={<Ajuda />} />
      </Routes>
    </BrowserRouter>
  );
}

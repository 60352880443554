import './Ajuda.css';
import React, { useState } from 'react';
import { FaUser, FaBars } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { FaHeadset } from "react-icons/fa";

import Navbar from './Navbar';
function Ajuda() {

    const [isMenuActive, setIsMenuActive] = useState("") 
    const [menuActive, setMenuActive] = useState("")
    const [menuResponsivo, setMenuResponsivo] = useState(false)
    const [value, setValue] = useState("menuBanco")
  
    function handleMenu(value){
      console.log(value)
      
      if(menuActive === value){
        setIsMenuActive(false)
        setMenuActive("")
      }
      else{
        setIsMenuActive(true)
        setMenuActive(value)
      }
    }
  
    function handleResponsiveMenu(){
      if(!menuResponsivo)
        setMenuResponsivo(true)
      else 
        setMenuResponsivo(false)
    }
  
    function handleActive(){
      setIsMenuActive(false)
      setMenuActive("")
    }

  return (
    <div>
        <div className="animacao_fundo"></div>
        <section className="__ajuda__atendimentos">
        <header className="__home__cabecalho">
          <Navbar 
          handleMenu={handleMenu} 
          handleResponsiveMenu={handleResponsiveMenu} 
          isMenuActive={isMenuActive}
          setIsMenuActive={setIsMenuActive}
          menuActive={menuActive}
          setMenuActive={setMenuActive}
          menuResponsivo={menuResponsivo}
          setMenuResponsivo={setMenuResponsivo}
          />
        </header>
        <h1 className="__ajuda__tituloAtendimentos">ATENDIMENTO NORWAY</h1>
        <div className="__ajuda__divisaoAtendimentos" onClick={handleActive}>
            <div className="__ajuda__divisaoAtendimento">
                <div className="__ajuda__divisaoTitulo">
                    <div className="__ajuda__divisaoIcone">
                        <FaUser className="fa-solid fa-user __ajuda__iconeAtendimento"></FaUser>
                    </div>
                    <span className="__ajuda__subtituloAtendimento">Atendimento para você</span>
                </div>
                <div>
                    <p className="__ajuda__tituloContato">Capital e regiões metropolitanas.</p>
                    <span className="__ajuda__contato">55 11 2050-2222</span>
                </div>
                <div>
                    <p className="__ajuda__tituloContato">Demais localidades.</p>
                    <span className="__ajuda__contato">0800 881 7888</span>
                </div>
                <div>
                    <p className="__ajuda__tituloContato">Horários de atendimento.</p>
                    <span className="__ajuda__contato __ajuda__contatoDia">Segunda a Sexta-Feira das 08:00 as 18:00</span>
                </div>
            </div>
            <div className="__ajuda__divisaoAtendimento">
                <div className="__ajuda__divisaoTitulo">
                    <div className="__ajuda__divisaoIcone">
                        <FaBuilding className="fa-solid fa-building __ajuda__iconeAtendimento"></FaBuilding>
                    </div>
                    <span className="__ajuda__subtituloAtendimento">Atendimento para sua empresa</span>
                </div>
                <div>
                    <p className="__ajuda__tituloContato">Capital e regiões metropolitanas.</p>
                    <span className="__ajuda__contato">55 11 2050-2222</span>
                </div>
                <div>
                    <p className="__ajuda__tituloContato">Todas as regiões.</p>
                    <span className="__ajuda__contato">0800 881 7888</span>
                </div>
                <div>
                    <p className="__ajuda__tituloContato">Horários de atendimento.</p>
                    <span className="__ajuda__contato __ajuda__contatoDia">Segunda a Sexta-Feira das 08:00 as 18:00</span>
                </div>
            </div>
            <div className="__ajuda__divisaoAtendimento">
                <div className="__ajuda__divisaoTitulo">
                    <div className="__ajuda__divisaoIcone">
                        <FaHeadset className="fa-solid fa-headset __ajuda__iconeAtendimento"></FaHeadset>
                    </div>
                    <span className="__ajuda__subtituloAtendimento">SAC</span>
                </div>
                <div>
                    <p className="__ajuda__tituloContato __ajuda__sobreTitulo">Serviço de atendimento ao cliente – Informações, Cancelamentos e Reclamações</p>
                </div>
                <div>
                    <p className="__ajuda__tituloContato">Todas as regiões.</p>
                    <span className="__ajuda__contato">0800 881 7888</span>
                </div>
                <div>
                    <p className="__ajuda__tituloContato">Horários de atendimento.</p>
                    <span className="__ajuda__contato __ajuda__contatoDia">Segunda a Sexta-Feira das 08:00 as 18:00</span>
                </div>
                <div>
                    <p className="__ajuda__tituloContato __ajuda__sobreTitulo">Ouvidoria</p>
                    <p className="__ajuda__tituloContato">Todas as regiões</p>
                    <span className="__ajuda__contato">0800 881 7888</span>
                </div>
                <div>
                    <p className="__ajuda__tituloContato">Horários de atendimento.</p>
                    <span className="__ajuda__contato">Segunda a Sexta-Feira das 08:00 as 18:00</span>
                </div>
            </div>
        </div>
        <div class="__home__divisaoFooter"></div>
        </section>
    </div>
  );
}

export default Ajuda;
